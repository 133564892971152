import { useAuth0 } from '@auth0/auth0-react';
import { FullStory } from '@fullstory/browser';
import React, { useEffect, useState } from 'react';

import { useAppContext } from 'src/context';
import { Loader, Scanner } from '../components';
import { resetLoadAttemptId } from '../utils';

const Home = () => {
  const [bufferPeriod, setBufferPeriod] = useState<boolean>(true);
  const { setCanRedirect } = useAppContext();
  const { user } = useAuth0();

  useEffect(() => {
    // prevents asking for camera permissions if we redirect immediately (in Breadcrumbs)
    setTimeout(() => {
      setBufferPeriod(false);
    }, 100);
  }, []);

  useEffect(() => {
    // treat every new scan as a new attempt
    resetLoadAttemptId();

    // landing on the homepage should prevent deeplink protection from kicking in
    setCanRedirect(false);
  }, [setCanRedirect]);

  useEffect(() => {
    if (!user?.sub) return;

    FullStory('setIdentity', {
      uid: user.sub,
      properties: {
        kind: 'user',
        displayName: user?.nickname ?? user?.name ?? user?.email ?? 'QR User',
        email: user?.email,
      },
    });
  }, [user]);

  if (bufferPeriod) return <Loader />;

  return <Scanner />;
};

export default Home;
